var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",staticClass:"description-form",attrs:{"tag":"div"}},[_c('CardWrapper',[_c('VCardText',{staticClass:"description-form__grid"},[_c('ValidationProvider',{staticClass:"description-form__cell-3",attrs:{"name":"mastersCount","rules":"required|min_value:1","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"mastersCount","label":"Макс кол-во мастеров в группе","min":"1","type":"number","placeholder":"Введите количество","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.mastersCount),callback:function ($$v) {_vm.$set(_vm.form, "mastersCount", $$v)},expression:"form.mastersCount"}})]}}])}),_c('div',{staticClass:"text-body-1 description-form__cell-5"},[_c('span',{domProps:{"innerHTML":_vm._s('Дата окончания продаж и формирования групп с&nbsp;мастерами: ')}}),_c('span',{directives:[{name:"date",rawName:"v-date",value:({ date: _vm.course.salesEndAt, mask: 'dd MMM yyyy' }),expression:"{ date: course.salesEndAt, mask: 'dd MMM yyyy' }"}]})])],1),(!_vm.isSpecial)?[_c('VDivider',{staticClass:"my-0"}),_c('VCardText',{staticClass:"description-form__grid"},[_c('ValidationProvider',{staticClass:"description-form__cell-3",attrs:{"name":"mastersDayAllocation","rules":"required|min_value:1|max_value:28","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"mastersDayAllocation","label":"День месяца","min":"1","max":"28","type":"number","placeholder":"Введите день месяца","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.mastersAllocateDay),callback:function ($$v) {_vm.$set(_vm.form, "mastersAllocateDay", _vm._n($$v))},expression:"form.mastersAllocateDay"}})]}}],null,false,63500159)}),_c('div',{staticClass:"text-body-1 description-form__cell-5"},[_c('span',{domProps:{"innerHTML":_vm._s('Каждый месяц в выбранный день мастера будут удалены из&nbsp;чата своей группы в&nbsp;случае неоплаты курса.')}})])],1)]:_vm._e()],2),_c('CardWrapper',{scopedSlots:_vm._u([(!_vm.readonly)?{key:"footer",fn:function(){return [_c('VBtn',{attrs:{"color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v("Сохранить")])]},proxy:true}:null],null,true)},[_c('VCardText',[_c('div',{staticClass:"description-form__header space-b-6"},[_c('div',{staticClass:"text-body-3"},[_vm._v("Обучение")])]),_c('div',{staticClass:"description-form__content"},[_c('ValidationProvider',{staticClass:"description-form__fields-full",attrs:{"name":"group-description","rules":"requiredHTML","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"description-form__field__label text--darken-4 teal--text mb-2"},[_vm._v("Описание группы")]),_c('div',{staticClass:"description-form__field__editor",class:{ invalid: errors.length > 0 }},[_c('TiptapEditor',{key:_vm.$route.params.groupType,attrs:{"type":"MENTOR","placeholder":"Введите описание группы...","name":"group-description","showPreviewSwitcher":false,"borderTopNone":true,"showButtonList":_vm.buttonList,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),(errors[0])?_c('div',{staticClass:"question-form__field__error red--text mt-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),(_vm.isMGPlus)?_c('ValidationProvider',{staticClass:"description-form__fields-full mt-6",attrs:{"name":"standardDiff","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"name":"standardDiff","label":"Отличия от стандарта","placeholder":"Введите день месяца","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.standardDiff),callback:function ($$v) {_vm.$set(_vm.form, "standardDiff", $$v)},expression:"form.standardDiff"}})]}}],null,false,2361211159)}):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }