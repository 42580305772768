

















































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, CourseType, GroupLargeResource, GroupStoreData, GroupType } from '@/store/types'

@Component({
  components: {
    TextAreaInput,
    TextInput,
    TiptapEditor,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class GroupDescriptionForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  @Prop({ default: false })
  private readonly!: boolean

  private form: GroupStoreData = {
    description: '',
    standardDiff: undefined,
    mastersAllocateDay: 0,
    mastersCount: 24,
  }

  private buttonList = ['bold', 'italic', 'underline', 'strike']

  private get isSpecial () {
    return this.course.type.value === CourseType.SPECIAL
  }

  private get isMGPlus() {
    return this.group.type.value === GroupType.PLUS
  }

  private created () {
    this.form = {
      description: this.group.description,
      standardDiff: this.group.standardDiff || undefined,
      mastersAllocateDay: !this.isSpecial ? this.group.mastersAllocateDay : undefined,
      mastersCount: this.group.mastersCount,
    }
  }

  @Debounce(500)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerCoursesModule.saveGroup({
            courseID: this.course.id,
            params: this.form,
            type: this.group.type.value as GroupType,
          })
            .then(() => {
              this.notifySuccess('Описание группы сохранено')
              requestAnimationFrame(() => (form.reset()))
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('$route.params.groupType')
  private watchGroupType() {
    this.form = {
      description: this.group.description,
      standardDiff: this.group.standardDiff || undefined,
      mastersAllocateDay: !this.isSpecial ? this.group.mastersAllocateDay : undefined,
      mastersCount: this.group.mastersCount,
    }
  }
}
