








import { Component, Prop, Mixins } from 'vue-property-decorator'

import GroupDescriptionForm from '@/components/forms/course/GroupDescriptionForm.vue'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import { CourseLargeResource, GroupLargeResource } from '@/store/types'

@Component({
  components: {
    GroupDescriptionForm,
  },
})
export default class CoursesItemGroupDescription extends Mixins(PermissionsMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource
}
